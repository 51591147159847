export const Programms = [
  {
    "name": "Primary School",
    "age": "Ages between 8-10",
    "discount": 0,
    "min_select": 3,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Maths',
        'price': 1100,
        'exist': false
      },
      {
        'id': 2,
        'name': 'English',
        'price': 1100,
        'exist': false
      },
      {
        'id': 3,
        'name': 'Science',
        'price': 1100,
        'exist': false
      },
      {
        'id': 4,
        'name': 'Computing',
        'price': 1100,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Global Perspectives',
        'price': 1100,
        'exist': false
      },
      {
        'id': 6,
        'name': 'French',
        'price': 1100,
        'exist': false
      },
      {
        'id': 7,
        'name': 'Spanish',
        'price': 1100,
        'exist': false
      },
      {
        'id': 8,
        'name': 'German',
        'price': 1100,
        'exist': false
      },
    ],
  },
  {
    "name": "Secondary School",
    "age": "Ages between 11-13",
    "discount": 0,
    "min_select": 3,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Maths',
        'price': 1320,
        'exist': true
      },
      {
        'id': 2,
        'name': 'English',
        'price': 1320,
        'exist': true
      },
      {
        'id': 3,
        'name': 'Science',
        'price': 1320,
        'exist': true
      },
      {
        'id': 4,
        'name': 'Computing',
        'price': 1320,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Global Perspectives',
        'price': 1320,
        'exist': false
      },
      {
        'id': 6,
        'name': 'French',
        'price': 1320,
        'exist': false
      },
      {
        'id': 7,
        'name': 'Spanish',
        'price': 1320,
        'exist': false
      },
      {
        'id': 8,
        'name': 'German',
        'price': 1320,
        'exist': false
      },
    ],
  },
  {
    "name": "I/GCSE School",
    "age": "Ages between 14-16",
    "discount": 0,
    "min_select": 3,
    "selected": 5,
    "condition": {
      "selected": 5,
      "discount": 9.0909
    },
    "package": [
      {
        'id': 1,
        'name': 'Biology',
        'price': 1584,
        'exist': true
      },
      {
        'id': 2,
        'name': 'Business Studies',
        'price': 1584,
        'exist': true
      },
      {
        'id': 3,
        'name': 'Chemistry',
        'price': 1584,
        'exist': true
      },
      {
        'id': 4,
        'name': 'Computer Science',
        'price': 1584,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Economics',
        'price': 1584,
        'exist': false
      },
      {
        'id': 6,
        'name': 'English - First Language',
        'price': 1584,
        'exist': false
      },
      {
        'id': 7,
        'name': 'English - Literature in English',
        'price': 1584,
        'exist': false
      },
      {
        'id': 8,
        'name': 'English (as an Additional Language)',
        'price': 1584,
        'exist': false
      },
      {
        'id': 9,
        'name': 'Geography',
        'price': 1584,
        'exist': false
      },
      {
        'id': 10,
        'name': 'Global Perspectives',
        'price': 1584,
        'exist': false
      },
      {
        'id': 11,
        'name': 'History',
        'price': 1584,
        'exist': false
      },
      {
        'id': 12,
        'name': 'Mathematics',
        'price': 1584,
        'exist': false
      },
      {
        'id': 13,
        'name': 'Mathematics - Additional',
        'price': 1584,
        'exist': false
      },
      {
        'id': 14,
        'name': 'Physics',
        'price': 1584,
        'exist': false
      },
      {
        'id': 15,
        'name': 'Sociology',
        'price': 1584,
        'exist': false
      },
      {
        'id': 16,
        'name': 'Spanish',
        'price': 1584,
        'exist': false
      },
    ],
  },

]

export const ALevelProgramm = {
  "name": "A Level",
  "age": "Ages between 16-18",
  "price_condition": [
    { 1: 3400 },
    { 2: 5867 },
    { 3: 7500 },
    { 4: 8733 },
    { 5: 9867 },
    { 6: 11840.4 },
    { 7: 13813.8 },
    { 8: 15787.2 },
    { 9: 17760.6 },
    { 10: 19734 },
    { 11: 21707.4 },
    { 12: 23680.8 },
    { 13: 25654.2 },
    { 14: 27627.6 },
    { 15: 29601 },
    { 16: 31574.4 },
    { 17: 33547.8 },
  ],
  "discount": 0,
  "min_select": 1,
  "selected": 3,
  "package": [
    {
      'id': 1,
      'name': 'Biology',
      'price': 2750,
      'exist': true
    },
    {
      'id': 2,
      'name': 'Business',
      'price': 2750,
      'exist': true
    },
    {
      'id': 3,
      'name': 'Chemistry',
      'price': 2750,
      'exist': true
    },
    {
      'id': 4,
      'name': 'Computer Science',
      'price': 2750,
      'exist': false
    },
    {
      'id': 5,
      'name': 'Economics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 6,
      'name': 'English - Literature',
      'price': 2750,
      'exist': false
    },
    {
      'id': 7,
      'name': 'English Language',
      'price': 2750,
      'exist': false
    },
    {
      'id': 8,
      'name': 'Geography',
      'price': 2750,
      'exist': false
    },
    {
      'id': 9,
      'name': 'Global Perspectives & Research',
      'price': 2750,
      'exist': false
    },
    {
      'id': 10,
      'name': 'History',
      'price': 2750,
      'exist': false
    },
    {
      'id': 11,
      'name': 'Law',
      'price': 2750,
      'exist': false
    },
    {
      'id': 12,
      'name': 'Mathematics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 13,
      'name': 'Mathematics - Further',
      'price': 2750,
      'exist': false
    },
    {
      'id': 14,
      'name': 'Media Studies',
      'price': 2750,
      'exist': false
    },
    {
      'id': 15,
      'name': 'Physics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 16,
      'name': 'Psychology',
      'price': 2750,
      'exist': false
    },
    {
      'id': 17,
      'name': 'Sociology',
      'price': 2750,
      'exist': false
    },
  ],
}